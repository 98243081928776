/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext } from 'react';
import { LightGrayText } from '../components/flowComponents';
import { FC_DARK_BLUE, FC_LIGHTER_BLUE } from '../emotionVariables';

import { InterstitialContext } from './WelcomeInterstitial';

export const ADHDTestimonialPane = () => {
  const { largeScreenView } = useContext(InterstitialContext)

  return (
    <>
      <h2 css={css`
        color: ${FC_DARK_BLUE};
        margin-bottom: ${largeScreenView ? '54' : '40'}px;
        text-align: center;
      `}>
        Come for the body-doubling, stay for the community<br/>
      </h2>
      <div css={css`
        background: ${FC_LIGHTER_BLUE};
        border-radius: 16px;
        width: ${largeScreenView ? '360' : '311'}px;
        height: ${largeScreenView ? '242' : '188'}px;

        display: flex;
        flex-direction: column;
        align-items: center;

        margin-bottom: ${largeScreenView ? '62' : '24'}px;
      `}>
        <LightGrayText customCss={css`
          width: 281px;

          font-size: 20px;
          line-height: 28px;
          text-align: center;
          letter-spacing: 0.5px;

          margin-top: 48px;
          margin-bottom: ${largeScreenView ? '58' : '36'}px;
        `}>
         "I actually haven't taken my Adderall since I started using Flow Club.
         Which is kind of amazing, though I'm sure it's not typical."
        </LightGrayText>
      </div>
      <div css={css`
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.15px;
        color: ${FC_DARK_BLUE};
      `}>
        <img width={45} height={45} src="https://storage.googleapis.com/flowclub-static/images/hosts/thumbnails/julie_100x100.jpeg" alt="Julie" /><br/>
        <a href="https://www.protocol.com/workplace/flow-club-productivity-virtual-coworking" target="_blank" rel="noreferrer">
        Prof Julie Alexander, in an interview with Protocol</a>
      </div>
    </>
  )
}